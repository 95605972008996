<div *ngIf="showSpinner" class="spinner blue">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<div class="contents-link" *ngIf="!showSpinner && tagTitle">
  <a routerLink="/{{ Constant.rpContentsList }}/{{ companyCode }}">コンテンツ</a>
  <span class="arrow">></span>
  <span class="title-link">#{{ tagTitle }}</span>
</div>
<div *ngIf="!showSpinner && contentList?.length == 0" class="not-contents">
  公開中のコンテンツがありません。<br>公開までお待ちください。
</div>

<div class="main-flex">
  <div class="contents-container" *ngIf="!showSpinner" [id]="Constant.elementIdContentsRightMenuArea">
    <div class="left unit" *ngIf="contentList?.length>0">
      <div class="header">#{{ tagTitle }}に関するコンテンツ</div>
      <div class="flex">
        <ng-container *ngFor="let content of contentList; index as i; first as first;" #tagContents>
          <ng-container *ngIf="content.code == null">
            <ng-container *ngIf="content.endFlg == false">
              <div class="more-contents">
                <div *ngIf="isDataGetting" class="spinner blue">
                  <mat-spinner diameter="30"></mat-spinner>
                </div>
                <div class="more-contents-btn" (click)="onNextData()">
                  もっと見る <img src="assets/company/arrow_under.png">
                </div>
              </div>
            </ng-container>
            <div *ngIf="content.endFlg" class="more-contents-bottom"></div>
          </ng-container>

          <ng-container>
          <div *ngIf="content.code != null" class="box">
            <div class="rect contentrect" [class.first]="first">
              <div class="image-container">
                <a *ngIf="content.image_url" routerLink="/{{Constant.rpContentsDetail}}/{{companyCode}}/{{content.code}}">
                  <img class="image" [src]="content.image_url" appImageLoadingError />
                  <div class="image-back" [style.background-image]="'url(' + content.image_url + ')'"></div>
                </a>
                <a *ngIf="content.image_url===''" routerLink="/{{Constant.rpContentsDetail}}/{{companyCode}}/{{content.code}}">
                  <img class="image noimage" lazyLoad="assets/common/noimage.png" appImageLoadingError />
                  <div class="image-back-noimage"></div>
                </a>
              </div>
              <div class="title-container">
                <a class="title" routerLink="/{{Constant.rpContentsDetail}}/{{companyCode}}/{{content.code}}">{{ content.title }}</a>
              </div>
              <div class="like-area">
                <div class="like" (click)="onLikeButton(content)" [class.liked]="content.good_flag == 1" [class.disabled]="isLikePosting">
                  <img src="assets/company/icon_heart_R.png" *ngIf="content.good_flag != 1">
                  <img src="assets/company/icon_heart_W.png" *ngIf="content.good_flag == 1">
                  <div>{{ content.good_cnt }}</div>
                </div>
              </div>
            </div>
            <ng-container *ngTemplateOutlet="tagarea; context:{ content: content }"></ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="right-menu-area" *ngIf="categoryListData?.length>0 || tagListData?.length>0">
    <div class="right-menu" *ngIf="!showSpinner" [id]="Constant.elementIdContentsRightMenu">
      <ng-container *ngIf="categoryListData?.length>0">
        <div class="header">カテゴリー</div>
        <div class="category-area">
          <ng-container *ngFor="let category of categoryListData; index as i; first as first;" #rightMenuCategory>
            <a routerLink="/{{Constant.rpContentsCategory}}/{{companyCode}}/{{category.url}}" class="category-box">
              <div class="title">{{category.title}}</div>
              <img class="image" [src]="category.image_url" appImageLoadingError />
              <div class="image-back" [style.background-image]="'url(' + category.image_url + ')'"></div>
            </a>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="tagListData?.length>0">
        <div class="header tag">#タグ</div>
        <ng-container *ngFor="let tag of tagListData; index as i; first as first;" #rightMenuTag>
          <a class="a-tag" (click)="onTag(tag.id)">{{ tag.title }}</a>
        </ng-container>
      </ng-container>
    </div>

  </div>
</div>

<div class="tag-list-area" *ngIf="tagListData?.length>0">
  <div class="unit">
    <div class="header">#タグ</div>
    <div class="tag-list-inline">
      <ng-container *ngFor="let tag of tagListData; index as i; first as first;">
        <a class="tag" (click)="onTag(tag.id)">{{ tag.title }}</a>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="categoryListData?.length > 0" class="category-list-area">
  <div class="header categorylist">カテゴリー</div>
  <app-slider-category [data]="categoryParam" *ngIf="categoryParam"></app-slider-category>
</div>

<app-tsunagaru [data]="tsunagaruData" *ngIf="tsunagaruData"></app-tsunagaru>

<div class="topic-path-sp" *ngIf="!showSpinner && tagTitle">
  <div><a routerLink="/{{ Constant.rpContentsList }}/{{ companyCode }}">コンテンツ</a></div>
  <div>
    <span class="arrow">></span>
    <span class="title-link">#{{ tagTitle }}</span>
  </div>
</div>

<app-company-footer [companyInfo]="companyInfo" *ngIf="companyInfo"></app-company-footer>

<ng-template #tagarea let-content="content">
  <div class="tag-area">
    <div *ngIf="content.tag1Text" class="tag">#{{ content.tag1Text }}</div>
    <div *ngIf="content.tag2Text" class="tag">#{{ content.tag2Text }}</div>
    <div *ngIf="content.tag3Text" class="tag">#{{ content.tag3Text }}</div>
    <div *ngIf="content.tag4Text" class="tag">#{{ content.tag4Text }}</div>
    <div *ngIf="content.tag5Text" class="tag">#{{ content.tag5Text }}</div>
  </div>
</ng-template>
