<div class="cal-outer">
  <div class="cal-inner" id="cal-inner" #calElm>
    <div class="item"
    *ngFor="let item of data.list; index as i" [class.opacity]="i>sliderNo+2 || i<sliderNo">
      <a routerLink="/{{Constant.rpContentsCategory}}/{{data.companyCode}}/{{item.url}}" (click)="onTop()">
        <div class="title">{{item.title}}</div>
        <img class="image" [src]="item.image_url" appImageLoadingError />
        <div class="image-back" [style.background-image]="'url(' + item.image_url + ')'"></div>
      </a>
    </div>
  </div>
  <div class="cal-prev" (click)="prevSlide()" *ngIf="sliderNo>0"><img src="/assets/company/slide_left.png"></div>
  <div class="cal-next" (click)="nextSlide()" *ngIf="sliderNo+3<data.list.length"><img src="/assets/company/slide_right.png"></div>
</div>
