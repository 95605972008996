import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { ProfileService } from './../shared/profile.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SelectDialogComponent } from '../dialog/select-dialog/select-dialog.component';

@Component({
  selector: 'app-profile-desire-edit',
  templateUrl: './profile-desire-edit.component.html',
  styleUrls: ['./profile-desire-edit.component.css']
})
export class ProfileDesireEditComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public form: FormGroup;
  public profile; // プロフィール
  public master = {}; // マスタ
  public errorMessageTerm: string;
  private jobMaster = []; // 職種マスタ
  private placeMaster = []; // 勤務地マスタ
  public selectedDesireJob = [];
  public selectedDesireJobName = [];
  public selectedDesirePlace = [];
  public selectedDesirePlaceName: string[] = [];
  public setFormFlag = false;
  public dataSending = false;

  private subscriptionProfile: Subscription;
  private subscriptionMaster: Subscription;

  constructor(
    private router: Router,
    private common: CommonService,
    private dialog: MatDialog,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlProfile);

    // イベント登録
    this.subscriptionProfile = this.profileService.sharedProfile$.subscribe(data => {
      this.profile = data;
      if (!this.setFormFlag) {
        this.setValue();
      }
    });
    this.subscriptionMaster = this.profileService.sharedMaster$.subscribe(data => {
      data.forEach(element => {
        this.master[element.type] = element.data;
      });

      // 職種マスタ作成
      this.jobMaster = this.common.convertJobMaster(this.master[Constant.pmDesireJobType]);

      // 勤務地マスタ作成
      this.placeMaster = this.common.convertJobMaster(this.master[Constant.pmDesireJobPlaceType]);

      // フォーム作成
      this.initForm();

      if (!this.setFormFlag) {
        this.setValue();
      }
    });

    // プロフィール、マスタ取得
    const reset = false;
    this.profileService.getMaster(reset);
    this.profileService.getProfile(reset);
  }

  ngOnDestroy() {
    this.subscriptionProfile.unsubscribe();
    this.subscriptionMaster.unsubscribe();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  /* テンプレート起動 */
  // キャンセル
  onCancel() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpDesire]);
      })
      .catch(err => {});
  }

  // enter無効
  onKeyDown(event) {
    return event.preventDefault(); // enterを無効化
  }

  // フォーカス移動
  onNextFocus(len, value, nextElm) {
    this.common.nextFocus(len, value, nextElm);
  }

  // 未定チェック
  onChangeUndecided() {
    this.common.disabledTerm(this.form.get(Constant.apActiveUndecidedFlag).value, this.form);
  }

  // 登録
  onRegist() {
    this.errorMessageTerm = '';

    this.common
      .preCheck()
      .then(res => {
        this.registExec();
      })
      .catch(err => {});
  }

  // 職種選択
  onDesireJob() {
    const dialogRef = this.dialog.open(SelectDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      data: {
        type: Constant.sdTypeJob,
        title: Constant.sdTypeJobTitle,
        items: this.jobMaster,
        selected: this.selectedDesireJob
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.selectedDesireJob = [];
      this.selectedDesireJobName = [];
      if (result.length > 0) {
        this.selectedDesireJob = result;
        this.selectedDesireJobName = this.common.setShowJobNameAry(result);
      }
    });
  }

  // 勤務地選択
  onDesirePlace() {
    const dialogRef = this.dialog.open(SelectDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      data: {
        type: Constant.sdTypePlace,
        title: Constant.sdTypePlaceTitle,
        items: this.placeMaster,
        selected: this.selectedDesirePlace
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.selectedDesirePlaceName = [];
      this.selectedDesirePlace = [];
      if (result.length > 0) {
        result.forEach(element => {
          this.selectedDesirePlace.push(element.id);
          this.selectedDesirePlaceName.push(element[Constant.apItemValue]);
        });
      }
    });
  }

  // 卒業（予定）年度選択
  onGraduateYear() {
    const dialogRef = this.dialog.open(SelectDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      data: {
        type: Constant.sdTypeYear,
        title: Constant.sdTypeYearTitle,
        items: this.master[Constant.pmGraduateYearType],
        selected: this.profile[Constant.apGraduateYearType]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.profile[Constant.apGraduateYearType] = result[0];
      this.profile[Constant.apGraduateYearTypeName] = this.common.convertMasterIdToItemValue(
        this.profile[Constant.apGraduateYearType], this.master, Constant.pmGraduateYearType
      );
    });
  }

  /* プライベート */
  // フォーム作成
  private initForm() {
    const formControl = {};

    this.master[Constant.pmDesireEmployeementType].forEach(element => {
      const keyString: string = 'desire_employeement_' + element.id;
      formControl[keyString] = new FormControl();
    });

    formControl[Constant.tfStartYear] = new FormControl('', [
      Validators.min(Constant.fvActiveStartYear),
      Validators.pattern(/^[0-9]*$/)
    ]);
    formControl[Constant.tfStartMonth] = new FormControl('', [
      Validators.min(Constant.fvMinMonth),
      Validators.max(Constant.fvMaxMonth),
      Validators.pattern(/^[0-9]*$/)
    ]);
    formControl[Constant.tfEndYear] = new FormControl('', [
      Validators.min(Constant.fvActiveStartYear),
      Validators.pattern(/^[0-9]*$/)
    ]);
    formControl[Constant.tfEndMonth] = new FormControl('', [
      Validators.min(Constant.fvMinMonth),
      Validators.max(Constant.fvMaxMonth),
      Validators.pattern(/^[0-9]*$/)
    ]);
    formControl[Constant.apActiveUndecidedFlag] = new FormControl();
    formControl[Constant.apDesirejobUndecidedFlag] = new FormControl();
    formControl[Constant.apGraduateYearSkipFlag] = new FormControl();
    this.form = new FormGroup(formControl);
  }

  // フォームに値をセット
  private setValue() {
    if (!this.profile || !this.form) {
      return;
    }

    // 活動時期
    const startAry = this.profile[Constant.apActivePlannedStart].split('/');
    const endAry = this.profile[Constant.apActivePlannedEnd].split('/');

    if (this.profile[Constant.apActiveUndecidedFlag] === Constant.prfFlagOnStr) {
      // 未定
      this.common.disabledTerm(true, this.form);
    }

    if (startAry.length <= 1 || endAry.length <= 1) {
      startAry[0] = '';
      startAry[1] = '';
      endAry[0] = '';
      endAry[1] = '';
    }

    const formValue = {
      start_year: startAry[0],
      start_month: startAry[1],
      end_year: endAry[0],
      end_month: endAry[1],
      active_undecided_flag: Number(this.profile[Constant.apActiveUndecidedFlag]),
      desirejob_undecided_flag: this.profile[Constant.apDesirejobUndecidedFlag],
      graduate_year_skip_flag: this.profile[Constant.apGraduateYearSkipFlag],
    };

    // 雇用形態
    const employeementAry = this.profile[Constant.apDesireEmployeementId].split(',');
    this.master[Constant.pmDesireEmployeementType].forEach(element => {
      const keyString: string = 'desire_employeement_' + element.id;
      const value: boolean = employeementAry.indexOf(element.id.toString()) > -1 ? true : false;
      formValue[keyString] = value;
    });

    // 職種、勤務地
    this.selectedDesireJobName = this.common.setShowJobNameAry(this.profile[Constant.apDesirejob]);
    this.selectedDesirePlaceName = this.profile.desirejob_place.split(',');
    this.selectedDesireJob = this.profile[Constant.apDesirejob];
    this.selectedDesirePlace = this.profile.desirejob_place_id.split(',').map(Number);

    setTimeout(() => {
      this.form.setValue(formValue);
      this.setFormFlag = true;
    });
  }

  // 登録実行
  private registExec() {
    // 活動予定時期のチェック
    const startYear = this.form.get(Constant.tfStartYear).value;
    const startMonth = this.form.get(Constant.tfStartMonth).value;
    const endYear = this.form.get(Constant.tfEndYear).value;
    const endMonth = this.form.get(Constant.tfEndMonth).value;

    let active_start = this.common.dateFromYearMonth(startYear, startMonth);
    let active_end = this.common.dateFromYearMonth(endYear, endMonth);
    const active_undecided = this.form.get(Constant.apActiveUndecidedFlag).value;
    if (startYear || startMonth || endYear || endMonth) {
      if (active_undecided) {
        if (!active_start || !active_end || active_start > active_end ||
          isNaN(Date.parse(active_start)) || isNaN(Date.parse(active_end)) ) {
          // 未定でエラーの場合、期間をリセットして保存
          active_start = '';
          active_end = '';
        }
      } else {
        if (!active_start || !active_end) {
          this.errorMessageTerm = Constant.msgErrorInputAllTerm;
          return;
        } else if (active_start > active_end) {
          this.errorMessageTerm = Constant.msgErrorInvalidTerm;
          return;
        }
      }
    }

    // データ送信中スピナー表示
    this.dataSending = true;

    // GAイベント
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionUpdateProfileDesire);

    const data = {};

    // 雇用形態
    const desire_employeement = [];
    this.master[Constant.pmDesireEmployeementType].forEach(element => {
      if (this.form.get('desire_employeement_' + element.id).value) {
        desire_employeement.push({ key: 'id', value: element.id });
      }
    });
    data[Constant.apDesireEmployeement] = desire_employeement;

    // 職種
    const desire_job = [];
    this.selectedDesireJob.forEach(element => {
      desire_job.push({
        large_key: 'id',
        large_value: element[Constant.apLargeId],
        key: 'id',
        value: element.id
      });
    });
    data[Constant.apDesirejob] = desire_job;

    // 勤務地
    const desire_place = [];
    this.selectedDesirePlace.forEach(element => {
      if (element) {
        desire_place.push({ key: 'id', value: element });
      }
    });
    data[Constant.apDesirejobPlace] = desire_place;

    data[Constant.apBase] = [
      { key: Constant.apActivePlannedStart, value: active_start },
      { key: Constant.apActivePlannedEnd, value: active_end },
      { key: Constant.apActiveUndecidedFlag, value: Number(active_undecided) },
      { key: Constant.apDesirejobUndecidedFlag, value: Number(this.form.get(Constant.apDesirejobUndecidedFlag).value) },
      { key: Constant.apGraduateYearType, value: this.profile[Constant.apGraduateYearType] },  // 卒業年度
      { key: Constant.apGraduateYearSkipFlag, value: Number(this.form.get(Constant.apGraduateYearSkipFlag).value) },  // 卒業年度スキップ
    ];

    const msgTitle = '希望情報';
    this.profileService
      .postProfile(data)
      .then(result => {
        this.common.debug().log(result);
        this.dataSending = false;
        if (result.status === Constant.OK) {
          this.common.showMessage(true, msgTitle);
          this.router.navigate([Constant.rpDesire]);
        } else {
          // APIエラーの場合は「更新に失敗しました」トーストを表示
          this.common.showMessage(false, msgTitle);
        }
      })
      .catch(err => {
        this.common.debug().log(err);
        this.dataSending = false;
        // オフライン等で結果が取得出来なかった場合は「失敗した可能性があります」トーストを表示
        this.common.showToastMessage(Constant.msgUpdateError);
      });
  }

  // Validation
  // 雇用形態
  desireEmploymentTypeValidation() {
    let isInvalid = true;
    this.master[Constant.pmDesireEmployeementType].forEach(element => {
      if (this.form.get('desire_employeement_' + element.id).value) {
        isInvalid = false;
      }
    });
    return isInvalid;
  }

  // 職種
  // 活動予定時期用のバリデーション
  isActivePlanValid() {
    let isInvalid = true;
    const active_undecided = this.form.get(Constant.apActiveUndecidedFlag).value;
    if (active_undecided) {
      // 未定のチェックが入っていた場合はOK
      isInvalid = false;
    }

    const startYear = this.form.get(Constant.tfStartYear).value;
    const startMonth = this.form.get(Constant.tfStartMonth).value;
    const endYear = this.form.get(Constant.tfEndYear).value;
    const endMonth = this.form.get(Constant.tfEndMonth).value;

    if (startYear && startMonth && endYear && endMonth) {
      isInvalid = false;
    }

    return isInvalid;
  }

  // 職種選択のバリデーション
  isDesireJobValid() {
    if (this.form.value[Constant.apDesirejobUndecidedFlag] || this.selectedDesireJob.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  // 卒業年度選択バリデーション
  isGraduateValid() {
    if (this.form.value[Constant.apGraduateYearSkipFlag] || this.profile[Constant.apGraduateYearTypeName]) {
      return false;
    } else {
      return true;
    }
  }
}
